<template>
  <div v-observe-visibility="visibilityChanged">
    <div
      class="product-tile relative flex flex-col justify-between items-center text-center sm:justify-start hover:bg-grey-18 hover:shadow-inner"
      v-if="isVisible"
      :class="{ 'pb-15': showAddToCartBtn, 'pb-10': !showAddToCartBtn }"
    >
      <slot name="recommendedBadge"></slot>
      <div>
        <router-link
          :to="productLink"
          data-testid="productLink"
          class="product-tile__image-wrapper overflow-hidden"
        >
          <img
            :alt="product.name"
            :src="thumbnailObj.src"
            loading="lazy"
            data-testid="productImage"
            :class="`product-tile__image product-tile__image__${product.brand_id}`"
            :style="{ paddingTop: imageIndent }"
          >
        </router-link>
      </div>

      <div class="mt-6 lg:mt-2 px-4">
        <h2 class="text-base leading-h6 font-normal">
          <router-link
            :class="`text-${color}`"
            :to="productLink"
            data-testid="productLink"
          >
            {{ product.name | htmlDecode }}
          </router-link>
        </h2>

        <div
          v-if="product.type_id !== 'grouped' && product.type_id !== 'bundle'"
          class="text-dark text-above-md italic font-serif leading-none mt-xs"
        >
          <span v-show="!rulePrice">
            <span v-if="product.special_price && parseFloat(product.special_price) > 0" class="font-semibold">
              {{ product.priceInclTax | price }}
            </span>

            <span v-if="product.special_price && parseFloat(product.originalPriceInclTax) > 0" class="ml-1 line-through">
              {{ product.originalPriceInclTax | price }}
            </span>

            <span v-if="!product.special_price && parseFloat(product.priceInclTax) > 0">
              {{ product.priceInclTax | price }}
            </span>
          </span>
            <span v-show="rulePrice">
            {{ rulePrice | price }}
          </span>
        </div>
        <div
          v-else
          class="text-dark text-above-md italic font-serif leading-none mt-xs"
        >
          <span v-if="productPrices.length">
            {{ productPrices[0] | price }} - {{ productPrices[productPrices.length - 1] | price }}
          </span>
        </div>
      </div>

      <div
        v-if="showAddToCartBtn"
        class="product-tile__add-tocart-box pt-4 lg:flex flex-col justify-center items-center"
        :class="{ 'product-tile__add-tocart-box--back-in-stock': product.stock && !product.stock.is_in_stock }"
      >
        <add-to-cart
          v-if="product.stock
          && product.stock.is_in_stock
          || product.type_id === 'grouped'
          || product.type_id === 'bundle'"
          :product="product"
          :color="addToCart"
          :btn-solid="true"
          :category-id="categoryId"
          :product-id="productId"
        >
          {{ $t('Quick Add') }}
        </add-to-cart>
        <button-full
          v-else-if="product.type_id === 'configurable'"
          :small="true"
          :color="'black'"
          :link="productLink">
          {{ $t('View Product') }}
        </button-full>
        <back-in-stock v-else :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import rootStore from '@vue-storefront/core/store'
import { ProductTile } from '@vue-storefront/core/modules/catalog/components/ProductTile.ts'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import ProductImageScaling from 'theme/mixins/blocks/ProductImageScaling'
import AddToCart from 'theme/components/core/AddToCart'
import BackInStock from 'theme/components/theme/BackInStock'
import config from 'config'
import {getThumbnailPath, productThumbnailPath} from '@vue-storefront/core/helpers'
import ButtonFull from 'theme/components/theme/ButtonFull'

export default {
  components: {
    ButtonFull,
    AddToCart,
    BackInStock
  },
  mixins: [
    ProductTile,
    ProductImageScaling,
    PriceRules
  ],
  data () {
    return {
      thumbnailObjTest: {
        src: 'https://pro.luxurybrandpartners.com/media/catalog/product/cache/1/lbp_catalog/x320/040ec09b1e35df139433887a97daa66f/r/c/rco_pro_thumb__highdrive.png'
      },
      addToCart: 'black',
      isVisible: false
    }
  },
  props: {
    categoryId: {
      type: [Number, String],
      required: true
    },
    productId: {
      type: [Number, String],
      required: true
    },
    labelsActive: {
      type: Boolean,
      requred: false,
      default: true
    },
    showAddToCartBtn: {
      type: Boolean,
      requred: false,
      default: true
    },
    onlyImage: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: 'primary'
    }
  },
  computed: {
    thumbnail () {
      return this.getThumbnail(this.product.image, config.products.thumbnails.width, config.products.thumbnails.height)
    },
    thumbnailObj () {
      return {
        src: this.thumbnail,
        loading: this.placeholder,
        error: this.placeholder
      }
    },
    getPackingType () {
      let pt = this.product.packaging_type || 0

      return config.packagingType ? config.packagingType[pt] || '' : ''
    },
    productLinks () {
      let productLinks = this.product.type_id === 'grouped' ? this.product['product_links'] : this.product['bundle_options'][0]['product_links']

      if (productLinks) {
        productLinks.sort((a, b) => {
          if (!a.product || !b.product) return
          return a.product.price - b.product.price
        })
      }
      return productLinks || []
    },
    rulePrice () {
      return this.getRulePrice(this.product)
    },
    productPrices () {
      if (!this.productLinks.length) return false

      let productPrices = []

      this.productLinks.forEach(link => {
        if (!link.product) return

        let rulePrice = this.getRulePrice(link.product)

        if (rulePrice) {
          productPrices.push(rulePrice)
        } else {
          productPrices.push(link.product.price)
        }
      })

      return productPrices
    },
    imageIndent () {
      let indents = config.entities.category.imageIndent

      return indents && indents[this.product['packaging_type']] ? `${300 * (indents[this.product['packaging_type']] / 100)}px` : 0
    }
  },
  methods: {
    onProductPriceUpdate (product) {
      if (product.sku === this.product.sku) {
        // Object.assign(this.product, product)
      }
    },
    visibilityChanged (isVisible, entry) {
      if (isVisible) {
        this.isVisible = true

        if (rootStore.state.config.products.configurableChildrenStockPrefetchDynamic && rootStore.products.filterUnavailableVariants) {
          const skus = [this.product.sku]

          if (this.product.type_id === 'configurable' && this.product.configurable_children && this.product.configurable_children.length > 0) {
            for (const confChild of this.product.configurable_children) {
              const cachedItem = rootStore.state.stock.cache[confChild.id]

              if (typeof cachedItem === 'undefined' || cachedItem === null) {
                skus.push(confChild.sku)
              }
            }
            if (skus.length > 0) {
              rootStore.dispatch('stock/list', { skus: skus }) // store it in the cache
            }
          }
        }
      }
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-priceupdate', this.onProductPriceUpdate)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-priceupdate', this.onProductPriceUpdate)
  }
}
</script>

<style lang="scss" scoped>
.product-tile {
  $block: &;
  transition: background-color .1s;
  height: 100%;

  @screen lg {
    //overflow: hidden;
  }

  &__add-tocart-box {
    @screen lg {
      bottom: 7px;
    }
    bottom: 0;
    position: absolute;
    transition: bottom 0.25s ease;
  }

  &:hover {
    background-image: linear-gradient(to bottom, #d7d7d7 0%, #eee 100%);

    #{$block}__add-tocart-box {
      @screen lg {
        bottom: 7px;
      }

      &--back-in-stock {
        @screen lg {
          bottom: 7px;
        }
      }
    }
  }

  &__image-wrapper {
    @apply flex items-end;

    @screen sm {
      height: 320px;
    }
  }

  &__image {
    @apply block;
    mix-blend-mode: darken;
    max-width: 100%;

    &.product-tile__image__1 { // rco

    }

    &.product-tile__image__3 { // sc
      &.balm {
        @apply py-2 m-auto;
      }

      &.lip-cylinder {
        @apply py-1 m-auto;
      }
    }
  }

  &__description {
    height: 120px;

    @screen lg {
      min-height: 105px;
    }
  }

  &::v-deep {
    .back-in-stock {
      & > p {
        @apply m-0;
      }

      & > button {
        @apply border-primary border;
        line-height: 1rem;

        &:hover {
          @apply border-primary2 text-white;
        }
      }
    }
  }
}
</style>
